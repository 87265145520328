import React, { Component } from 'react';
import orderBy from 'lodash/orderBy';
import { Image } from '../../Common/Image';
import { getArticleOrCategorySlugs } from '../../../utilities';
import groupBy from 'lodash/groupBy';
import ArticleCardBlue from '../../Common/Article/ArticleCardBlue';

class NotLastCategoryContent extends Component {
     constructor(props) {
          super(props);

          this.state = {
               // currentChildCategory: this.props.category.hz__category[0].contentful_id,
               breedClicked: false,
               articlePerPage: 6,
               recentArticles: 12
          };
     }

     componentDidMount() {
          document.addEventListener('mousedown', this.handleClickOutside);
     }

     componentWillUnmount() {
          document.removeEventListener('mousedown', this.handleClickOutside);
     }

     // selectAChild = (e) => {
     //      e.preventDefault();
     //      this.setState({ currentChildCategory: e.target.value })
     // }

     // Get all articles of child category
     // No matter what category's level are, this can be applied for all categories
     getChildCategoryArticles = (category) => {
          let childCategoryArticles = this.getChildArticles(category.hz__category);

          if (Array.isArray(category.hz__article)) {
               childCategoryArticles = [...category.hz__article, ...childCategoryArticles];
          }

          return childCategoryArticles;
     };

     getChildArticles = (categories) => {
          let childArticles = [];
          if (typeof categories != 'undefined' && categories != null) {
               categories.forEach((category) => {
                    let articleArr = this.getChildArticles(category.hz__category);
                    childArticles = [...childArticles, ...category.hz__article, ...articleArr];
               });
          }

          return childArticles;
     };

     toggleBreed = (e) => {
          e.stopPropagation();
          this.setState({ breedClicked: !this.state.breedClicked });
     };

     loadMoreArticle = () => {
          this.setState({ recentArticles: this.state.recentArticles + this.state.articlePerPage });
     };

     showLessArticle = () => {
          this.setState({ recentArticles: this.state.articlePerPage });
     };

     setWrapperRef = (node) => {
          this.wrapperRef = node;
     };

     handleClickOutside = (event) => {
          if (this.state.breedClicked && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
               this.setState({ breedClicked: false });
          }
     };

     render() {
          const { category } = this.props;

          // let currentChildCategory = category.hz__category.find((category) => {
          //      return category.contentful_id == this.state.currentChildCategory;
          // })

          let allArticles = category.hz__article ? category.hz__article : [];

          // Get breeds articles from allArticles which come from the same category
          let allBreedArticles = allArticles.filter((article) => {
               return article.breedName;
          });

          category.hz__category.forEach((category) => {
               allArticles = [...allArticles, ...this.getChildCategoryArticles(category)];
          });

          // Remove null values from allArticles
          allArticles = allArticles.filter((article) => {
               return article != null;
          });

          // Remove breeds articles from allArticles
          // let allRecentArticles = allArticles.filter(article => {
          //      return !article.breedName;
          // });

          let allRecentArticles = allArticles;

          if (allRecentArticles.length >= 2) {
               allRecentArticles = orderBy(allRecentArticles, 'createdAt', 'desc');
          }

          let allBreedsTemp = groupBy(allBreedArticles, (article) => {
               return article.breedName
                    .trim()
                    .charAt(0)
                    .toUpperCase();
          });

          const allBreeds = {};
          Object.keys(allBreedsTemp)
               .sort()
               .forEach((key) => {
                    allBreeds[key] = allBreedsTemp[key];
               });

          let recentArticlesToShow = allRecentArticles ? allRecentArticles.slice(0, this.state.recentArticles) : [];

          return (
               <>
                    <section className="category-intro">
                         <div className="container">
                              <div className="row">
                                   <div className="col-lg-12 category-intro-title ">
                                        <h1>{category.h1 ? category.h1 : category.categoryName}</h1>
                                        {category.shortDescription && <p>{category.shortDescription}</p>}
                                        {category.hz__category.length > 0 && (
                                             <div className="sub-categories">
                                                  {category.hz__category.map((categoryChild, i) => {
                                                       let slugs =
                                                            '/healthzone/' +
                                                            getArticleOrCategorySlugs(category)
                                                                 .reverse()
                                                                 .join('/') +
                                                            '/' +
                                                            categoryChild.slug +
                                                            '/';

                                                       return (
                                                            <React.Fragment key={i}>
                                                                 {categoryChild.categoryName && (
                                                                      <a href={slugs} className="btn-healthzone">
                                                                           {categoryChild.categoryName}
                                                                      </a>
                                                                 )}
                                                            </React.Fragment>
                                                       );
                                                  })}
                                             </div>
                                        )}
                                        {category.isBreeds && (
                                             <>
                                                  <div className="breed-selector-label">{category.categoryName && <p>See all {category.categoryName.toLowerCase()}</p>}</div>
                                                  <div className="breed-selector" ref={this.setWrapperRef}>
                                                       <button className="breed-selector-button" onClick={this.toggleBreed}>
                                                            Select one from the list
                                                            <i className={`${this.state.breedClicked ? 'far fa-chevron-up' : 'far fa-chevron-down'}`}></i>
                                                       </button>
                                                       <div className={`breed-selector-box${this.state.breedClicked ? ' bs-anim-on bs-anim-off' : ''}`}>
                                                            {allBreedArticles.length > 0 ? (
                                                                 <>
                                                                      <div className="breed-selector-list">
                                                                           {Object.entries(allBreeds).map(([alphabet, breedArticles]) => {
                                                                                return (
                                                                                     <React.Fragment key={alphabet}>
                                                                                          <h2 id={alphabet} className="alphabet-title">
                                                                                               {alphabet}
                                                                                          </h2>
                                                                                          <ul>
                                                                                               {breedArticles.map((breedArticle, i) => {
                                                                                                    let slugs =
                                                                                                         '/healthzone/' +
                                                                                                         getArticleOrCategorySlugs(breedArticle.category)
                                                                                                              .reverse()
                                                                                                              .join('/') +
                                                                                                         '/' +
                                                                                                         breedArticle.slug +
                                                                                                         '/';

                                                                                                    return (
                                                                                                         <li key={i}>
                                                                                                              <a href={slugs}>{breedArticle.breedName}</a>
                                                                                                         </li>
                                                                                                    );
                                                                                               })}
                                                                                          </ul>
                                                                                     </React.Fragment>
                                                                                );
                                                                           })}
                                                                      </div>
                                                                      <div className="breed-selector-alpha">
                                                                           <ul>
                                                                                {Object.entries(allBreeds).map(([key, value]) => (
                                                                                     <li key={key}>
                                                                                          <a href={`#${key}`}>{key}</a>
                                                                                     </li>
                                                                                ))}
                                                                           </ul>
                                                                      </div>
                                                                 </>
                                                            ) : (
                                                                 <p>No articles</p>
                                                            )}
                                                       </div>
                                                  </div>
                                             </>
                                        )}
                                   </div>
                                   {category.categoryIntroImage && (
                                        <div className="col-lg-6">
                                             <div className="category-intro-image">
                                                  <Image image={category.categoryIntroImage}></Image>
                                             </div>
                                        </div>
                                   )}
                              </div>
                         </div>
                    </section>
                    {recentArticlesToShow.length > 0 && (
                         <section className={`recent-articles`}>
                              <div className="container">
                                   <div className="row articles-wrap">
                                        {recentArticlesToShow.map((article, i) => {
                                             let slugs =
                                                  '/healthzone/' +
                                                  getArticleOrCategorySlugs(article.category)
                                                       .reverse()
                                                       .join('/') +
                                                  '/' +
                                                  article.slug +
                                                  '/';

                                             return (
                                                  <div className="col-sm-6 col-md-4 article-block" key={i}>
                                                       {/* <div className="article-detail">
                                                                      <div className="image-wrap">
                                                                           <a href={slugs} tabIndex={-1}>
                                                                                {article.featuredImage ? (<Image image={article.featuredImage} altOverride={(article.contentName) ? article.contentName : ''} />) : <img loading="lazy" src="/images/no-dog.png" alt="no-dog" />}
                                                                           </a>
                                                                      </div>
                                                                      <div className="content">
                                                                           {article.contentName && (
                                                                                <a aria-label={article.contentName} href={slugs}>
                                                                                     <h3>{article.contentName}</h3>
                                                                                </a>
                                                                           )}
                                                                           {article.shortDescription && <p>{article.shortDescription}</p>}
                                                                      </div>
                                                                 </div> */}
                                                       <ArticleCardBlue article={article} slugs={slugs} />
                                                  </div>
                                             );
                                        })}
                                   </div>
                                   {allRecentArticles.length > 12 &&
                                        (this.state.recentArticles >= allRecentArticles.length ? (
                                             <button className="button-white" onClick={this.showLessArticle}>
                                                  Show less articles
                                             </button>
                                        ) : (
                                             <button className="button-white" onClick={this.loadMoreArticle}>
                                                  Load more articles
                                             </button>
                                        ))}
                              </div>
                         </section>
                    )}
               </>
          );
     }
}

export default NotLastCategoryContent;