import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getArticleOrCategorySlugs, getArticleOrCategoryBreadcrumbs, getArticleOrCategoryPreview, getPreviewArticleOrCategorySlugs } from '../../utilities';
import { getIsPreviewStatus } from '../../../state/app';

class Breadcrumbs extends Component {
    render() {
        const { category, article, isPreview } = this.props;
        var breadcrumbSlug = null;
        var categorySlug = null;
        if (typeof category != "undefined" && isPreview == true) {
            breadcrumbSlug = getArticleOrCategoryPreview(category);
            categorySlug = '/healthzone/' + getPreviewArticleOrCategorySlugs(category).reverse().join('/');
        } else {
            breadcrumbSlug = getArticleOrCategoryBreadcrumbs(category);
            categorySlug = '/healthzone/' + getArticleOrCategorySlugs(category).reverse().join('/');
        }
        return (
            <section className={`section-breadcrumb`}>
                <div className="container">
                    <div className="breadcrumb-content">
                        <span><a href="/healthzone/"><img loading="lazy" className="icon-home" src="/images/icons/iconhome.svg" alt="iconhome"/></a></span>
                        {
                            (typeof article != "undefined" && typeof category != "undefined") ? (
                                <>
                                    {
                                        breadcrumbSlug.reverse().map((val, i) => {
                                            return (
                                                <span key={i}>
                                                    <span className="seperate">
                                                        {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" className="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                                                            <path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path>
                                                        </svg> */}
                                                        /
                                                    </span>
                                                    <a href={val.slug + '/'} >{val.breadcrumb}</a>
                                                </span>
                                            )
                                        })
                                    }
                                    <span className="active">
                                        <span className="seperate">
                                            {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" className="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                                                <path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path>
                                            </svg>     */}
                                            /
                                        </span>
                                        {article.breadcrumbValue}
                                    </span>
                                </>
                            ) : (
                                    breadcrumbSlug.reverse().map((val, i) => {
                                        if (val.slug == categorySlug)
                                            return (
                                                <span className="active" key={i}>
                                                    <span className="seperate">
                                                        {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" className="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                                                            <path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path>
                                                        </svg> */}
                                                        /
                                                    </span>
                                                    {val.breadcrumb}
                                                </span>
                                            )
                                        return (
                                            <span key={i}>
                                                <span className="seperate">
                                                    {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" className="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                                                        <path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path>
                                                    </svg>     */}
                                                    /
                                                </span>
                                                <a href={val.slug + '/'} >{val.breadcrumb}</a>
                                            </span>
                                        )
                                    })
                                )

                        }
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        isPreview: getIsPreviewStatus(state),
    };
}

Breadcrumbs.propTypes = {
    isPreview: PropTypes.bool,
}

export default connect(mapStateToProps)(Breadcrumbs);
